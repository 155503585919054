@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: black !important; */
  /* overflow-y: scroll; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  /* height: 100svh; */
  /* height: 100vh; */
  /* Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100); */
  /* width: svw; */
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

/* Scrollbar track */
::-webkit-scrollbar {
  width: 10px;
  background-color: #2d2d2d; /* Dark background for the track */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #555; /* Slightly lighter color for the thumb for contrast */
  border-radius: 10px;
  border: 2px solid #2d2d2d; /* Dark border, same as the track color */
}

/* Scrollbar button (if present) */
::-webkit-scrollbar-button {
  display: none; /* This will hide them. If you want to style them, adjust accordingly */
}

/* Scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: #2d2d2d; /* Dark color for the corner */
}

body {
  scrollbar-width: thin;
  scrollbar-color: #555 #2d2d2d; /* Thumb and track color respectively */
}

.map-container {
  height: 400px;
}
